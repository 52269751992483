
@font-face {
  font-family: 'Quincy CF';
  font-style: normal;
  font-weight: normal; /* or 400 */
  src: url('App/assets/fonts/Quincy\ CF\ Regular.otf') format('opentype'), local('serif');
}

@font-face {
  font-family: 'TT Commons';
  font-style: normal;
  font-weight: normal; /* or 400 */
  src: url('App/assets/fonts/TT\ Commons\ Regular.otf') format('opentype'), local('Helvetica'), local('Arial'), local('sans-serif');
}
